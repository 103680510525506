@import url(https://fonts.googleapis.com/css2?family=Gwendolyn&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gwendolyn&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CSS FOR FONT IN REACT */

.Padding {
	padding: 40px 0px 40px 0px;
}

.Home {
  color: orange;
}

.navbar {
  background-color: #E81C33;
}

button.navbar-toggler {
	background-color: black;
	border-color: white;
	border-width: 3px;
}

.TopText {
  color: white;
  left: 20px;
  font-size: 20px;
  padding: 2px;
  font-family: 'Open Sans', sans-serif;

}

.BackBlack {
	background-color: black;
	padding-right: 10px;
	border-radius: 3px;
}

.BackBlack2 {
	background-color: black;
	padding: 1px 1px 0px 1px;
	border-radius: 3px;
	margin-left: 20px;
}

.ModalButton {
  color: white !important;
  border: 1px solid white !important;
  padding: 15px;
  font-size: 15px;
  background-color: black;
  font-family: 'Open Sans', sans-serif;

}

.Modal:active {
  background-color: white;
}

.Footer {
  background-color: #E81C33;
  color: #E81C33;
}


.MainPic {
	border: 2px solid #E81C33;
	height: 400px;
}

.RedDivBorder {
	background-color: #C3C3C3;
	height: 10px;
	color: #C3C3C3;
	padding: 5px;
	width: 100%;
}

.Blue {
	height: 100px;
	background-color: #E81C33;
}

/* SECOND PART ie LOCATIONS */
.New2 {
	text-align: center;
}

.Background {
	height: 200px;
}

.New {
	font-family: 'Open Sans', sans-serif;

	text-align: center;
	background-color: #f1b856;
}

.TopPic {
	height: 50px;
	width: 400px;
	border: 2px solid black;
}



.Space {
	background-color: white;
	color: white;
	padding: 40px;
}

.Centered {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.Picture {
width: 40px;

}

.carousel-control-prev-icon {
	background-color: green;
}

.carousel-control-next-icon {
	background-color: purple;
}
.carousel{
	text-align: center;
	margin: 0 auto;
}

.HeaderFont {
font-family: 'Gwendolyn', cursive;
font-size: 60px;
}

.LocationText {
	font-family: 'Open Sans', sans-serif;
	font-size: 30px;
}

.ParaText {
	font-family: 'Open Sans', sans-serif;
}

.BigFont {
	font-family: 'Open Sans', sans-serif;
	font-weight: 800;
}

.ParaText2 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 800;
}




